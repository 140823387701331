import { initArticleRoutes } from "./components/articles";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import authModule from "../store/modules/auth";
import userModule from "@/store/modules/user";
import _ from "lodash";
import { initNewsRoutes } from "./components/newsRoutes";
import { initProjectRoutes } from "./objects/projects";
import { initCrmRoutes } from "./objects/crm";
import { initFormRoutes } from "./components/forms";
import { initFilterRoutes } from "./components/filters";
import { initDocumentRoutes } from "./components/documents";
import { initCommodityRoutes } from "./components/commodities";
import { initEventRoutes } from "./components/events";
import { initEventCertificateRoutes } from "./components/eventCertifacates";
import { initCertificateTemplateRoutes } from "./components/certificate-templates";
import { initUserRoutes } from "./objects/users";
import { initRoleRoutes } from "./objects/roles";
import { initPersonRoutes } from "./components/personRoutes";
import { initConsentRoutes } from "./objects/consents";
import { initOrderRoutes } from "./objects/orders";
import { initClientWebOrderRoutes } from "./objects/clientWebOrders";
import { initClientCalculationRoutes } from "./objects/clientCalculations";
import { initVideoRoutes } from "./components/videoRoutes";
import { initMailScenarioRoutes } from "./objects/mailScenarios";
import { initProductModificationRoutes } from "./components/productModifications";
import { initBrandRoutes } from "./components/brands";
import { initWebinarRoutes } from "./components/vebinarRoutes";
import { initDataBusRoutes } from "./objects/databus";
import { initEventRegistrationRoutes } from "./components/initEventRegistrationRoutes";
import { initEventScheduleRoutes } from "./components/initEventScheduleRoutes";
import { initProfessionalAreaRoutes } from "./components/initProfessionalAreaRoutes";
import { initEventBroadcastRoutes } from "./components/initEventBroadcastRoutes";
import { initServiceRoutes } from "./components/serviceRoute";
import { initClinicRoutes } from "./components/initClinicRoutes";
import { initStationRoutes } from "./components/initStationRoutes";
import { initFeatureRoutes } from "./objects/initFeatureRoutes";
import { initPriceListRoutes } from "./components/initPriceListRoutes";
import { initComoanyRoutes } from "./components/initCompanyRoutes";
import { initDoctorRoutes } from "./components/initDoctorRoutes";
import { initReviewRoutes } from "./components/initReviewRoute";
import { initQuestionRoutes } from "./components/initQuestionRoutes";
import { initInvitationsRoutes } from "./components/initInvitationRoutes";
import { initSecurityGroupRoutes } from "./components/initSecurityGroupRoutes";
import { initStatisticRoutes } from "./components/initStatisticRoutes";
import { initDistrictRoutes } from "./components/initDistrictRoutes";
import { initUserConsentCancellationRoutes } from "@/router/objects/initUserConsentCancellationRoutes";
import { initResumeRoutes } from "@/router/objects/initResumeRoutes";
import { initRewardRoutes } from "@/router/components/initRewardRoutes";
import { initPriceCatalogRoutes } from "@/router/components/initPriceCatalogRoutes";
import { initAuthorRoutes } from "@/router/components/initAuthorRoutes";
import { initPageRoutes } from "@/router/components/initPageRoutes";
import { initBaseRoutes } from "@/router/base/initBaseRoutes";
import { initFileSystemRoutes } from "@/router/base/initFileSystemRoutes";
import { initBookletRoutes } from "@/router/components/initBookletRoutes";
import { initBookletConfigurationRoutes } from "@/router/components/initBookletConfigurationRoutes";
import { initCompanyPartnerRoutes } from "@/router/components/initCompanyPartnerRoutes";
import { initPromoactionRoutes } from "@/router/components/initPromoactionRoutes";
import { initProductClassRoutes } from "@/router/components/initProductClassRoutes";
import {initProductModelRoutes} from "@/router/components/initProductModelRoutes";
import {initProductSeriesRoutes} from "@/router/components/initProductSeriesRoutes";
import {initCaseRoutes} from "@/router/components/initCaseRoutes";
import {initRegionRoutes} from "@/router/objects/initRegionRoutes";

Vue.use(VueRouter);

export const initRoutes = (config: any) => {
  const routes: Array<RouteConfig> = [
    ...initBaseRoutes(config),
    ...initFileSystemRoutes(config),
    ...initArticleRoutes(config),
    ...initNewsRoutes(config),
    ...initProjectRoutes(config, false),
    ...initCrmRoutes(config),
    ...initFormRoutes(config, false),
    ...initFilterRoutes(config, false),
    ...initDocumentRoutes(config),
    ...initCommodityRoutes(config),
    ...initEventRoutes(config),
    ...initEventCertificateRoutes(config),
    ...initCertificateTemplateRoutes(config),
    ...initUserRoutes(config),
    ...initRoleRoutes(config),
    ...initPersonRoutes(config),
    ...initConsentRoutes(config),
    ...initOrderRoutes(config),
    ...initClientWebOrderRoutes(config),
    ...initClientCalculationRoutes(config),
    ...initVideoRoutes(config),
    ...initMailScenarioRoutes(config),
    ...initProductModificationRoutes(config),
    ...initBrandRoutes(config),
    ...initWebinarRoutes(config),
    ...initDataBusRoutes(config),
    ...initEventRegistrationRoutes(config),
    ...initEventScheduleRoutes(config),
    ...initProfessionalAreaRoutes(config),
    ...initEventBroadcastRoutes(config),
    ...initServiceRoutes(config),
    ...initClinicRoutes(config),
    ...initStationRoutes(config),
    ...initFeatureRoutes(config),
    ...initPriceListRoutes(config),
    ...initComoanyRoutes(config),
    ...initDoctorRoutes(config),
    ...initReviewRoutes(config),
    ...initQuestionRoutes(config),
    ...initInvitationsRoutes(config),
    ...initSecurityGroupRoutes(config),
    ...initStatisticRoutes(config),
    ...initDistrictRoutes(config),
    ...initUserConsentCancellationRoutes(config),
    ...initResumeRoutes(config),
    ...initRewardRoutes(config),
    ...initPriceCatalogRoutes(config),
    ...initAuthorRoutes(config),
    ...initPageRoutes(config),
    ...initBookletRoutes(config),
    ...initBookletConfigurationRoutes(config),
    ...initCompanyPartnerRoutes(config),
    ...initPromoactionRoutes(config),
    ...initProductClassRoutes(config),
    ...initProductModelRoutes(config),
    ...initProductSeriesRoutes(config),
    ...initCaseRoutes(config),
    ...initRegionRoutes(config)
  ];
  const accessRoles = [
    ...new Set(
      routes
        .filter((r: RouteConfig) => !!r.meta?.roles)
        .map((r: RouteConfig) => r.meta?.roles)
        .flat(),
    ),
  ];
  const getUserAccess = (to: Route, isSuccessAuth: boolean): boolean => {
    const roles = to?.meta?.roles ?? accessRoles;
    if (!isSuccessAuth) return false;
    if (roles.length === 0) return true;
    return _.intersection(userModule.roles, roles).length > 0;
  };
  const getNextRoute = (to: Route, hasAccess: boolean): string | undefined => {
    const next = !to?.meta?.requiresAuth || hasAccess ? undefined : "/login/auth";
    return to.name !== "home" && next !== undefined ? "/" : next;
  };

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(async (to: Route, _, next: NavigationGuardNext<Vue>) => {
    const successAuth = await authModule.autoLogin();
    const hasAccess = getUserAccess(to, successAuth);
    let route = getNextRoute(to, hasAccess);
    if (route == undefined) next();
    else next(route);
  });


  return router;
};
