export const initEventScheduleRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/event/:eventId/event-schedules",
          name: "event-schedule",
          component: () =>
            import("@/pages/manage/events/zones/schedules/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/event/:eventId/event-schedules/:scheduleId",
          name: "event-schedule-id",
          component: () =>
            import("@/pages/manage/events/zones/schedules/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
